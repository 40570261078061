<template>
  <div>
    <div  class="wrap" style="height: 100vh;background: #F8F8F8;overflow: hidden;"> 
      <div>
        <div class='blockTop'>
          <div class='lineLeft' :style='{"align-items": publicData.com_card_type == "fr" ? "flex-start" : "center" }'>
            <img src='@/assets/img/defaultHead.png' class='headImg'/>
            <div class='customerInfo' v-if='publicData.com_card_type == "fr"'>
              <div class='customerText'>{{publicData.com_cus_name}}</div>
              <div class='customerText'>{{mackMobile(publicData.loginMobile)}}</div>
              <div class='customerText'>法定代表人</div>
            </div>
            <div class='customerInfo' v-else style='height: 52px;'>
              <div class='customerText1'>{{mackMobile(publicData.loginMobile)}}</div>
            </div>
          </div>
          <div class='lineRight' @click='bindPerson'>
            <img src='@/assets/img/exchange.png' class='changeImg'/>
            <div class='btnText'>切换至自然人</div>
          </div>
        </div>
        <div class='blockCenter' :style='{"border-top-width": publicData.com_card_type == "fr" ? "1px" : "0px" }'>
          <div class='center_text'>绑定企业：{{companyStr.slice(0,-1)}}</div>
          <div class='viewBtn' @click='isShowBind'>查看</div>
        </div>
        <div class="blockOne"> 
          <span class="header">查询</span>
          <van-grid :column-num="4" :border="false">
            <van-grid-item 
              v-for="(item,index) in listQuery" 
              :key="index" 
              :icon="item.icon" 
              :text="item.text"
              @click="handdleNext(item.flag,item.text,item.code)"
            />
          </van-grid>
        </div> 
        <div class="blockOne"> 
          <span class="header">申请</span>
          <van-grid :column-num="4" :border="false">
            <van-grid-item 
              v-for="(item,index) in listApply" 
              :key="index" :icon="item.icon" 
              :text="item.text"
              :to='item.urlTo'
              @click="handdleNext(item.flag,item.text,item.code)"
            />
          </van-grid>
        </div>
      </div>
      <van-overlay :show="bindShow" :lock-scroll='false'>
        <div class="wrapper" @click.stop>
          <div class="block">
            <div class="closeTop">
              <img class="closeImg" @click='closeBind' src="@/assets/img/system-closeb@2x.png"/>
              <span class='blockTitle'>绑定企业</span>
            </div>
            <div style='height: 500px;overflow-y: scroll;'>
              <div class='bindLine' v-for='(item,index) in publicData.com_binds' :key='index'>
                <div class='bindTop'>{{item.company_name}}</div>
                <div class='bindBottom'>{{item.company_certid}}</div>
              </div>
            </div>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
  </template>
  <script>
    import { querytype, doLogin, getWxMenuCue } from '@/server'
    import { Toast,Dialog,Grid, GridItem,Overlay } from 'vant';
    import { setCookie, getCookie, domainUrl, hidewxOption, getShareData } from '@/lib/utils';
    import { getpublicbindstatus, comconlist } from '@/api/enterprise'
    import Loading from "@/components/Loading";
    // window.location.reload() 
    export default {
      components: {
        Loading
      },
      name:'personalCenter',
      data() {
        return{
          openId:getCookie('openId'),
          accessToken:this.$store.state.accessToken,
          bindStatus:localStorage.getItem("bindStatus"),
          listQuery:[{
            code:'menu_015',
            text:'租金计划',
            flag:'rentPlan',
            icon:require('@/assets/img/icon/rentPlan.png')
          },{
            code:'menu_018',
            text:'我的申请',
            flag:'myApply',
            icon:require('@/assets/img/icon/myApply.png')
          }],
          listApply:[
            {
              code:'menu_017',
              text:'前置保证金',
              flag:'advanceDeposit',
              icon:require('@/assets/img/icon/advanceDeposit.png')
            }
            // {
            // code:'menu_008',
            // text:'前置保证金',
            // flag:'earlyApart',
            // icon:require('@/assets/img/icon/earlyApart.png')
            // }
          ],
          bindShow: false,
          publicData: {},
          companyStr: '',
          companyStr1:''
        }
      },
      mounted(){
        sessionStorage.removeItem("origin");
        sessionStorage.removeItem("source");
        sessionStorage.removeItem("payInfoId");
        setTimeout(() => {
          sessionStorage.setItem('keyValue', 'enterprisePersonalCenter');
        }, 0);
      },
      created(){
        let urls = "";
        // 这个判断是处理ios分享失效的问题
        let userAgent = navigator.userAgent;
        if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
          urls = sessionStorage.getItem("originUrl");
        } else {
          urls = window.location.href;
        }
        getShareData(urls)
        //隐藏分享
				hidewxOption()
        window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
          if(sessionStorage.getItem('keyValue') == 'enterprisePersonalCenter') {
            document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false); 
            WeixinJSBridge.call('closeWindow');
          }
        }, false);
        getpublicbindstatus({
					openid: this.openId
				}).then((res)=>{
					if(res.wx_code == '0'){
						this.publicData = res;
            this.$store.commit('cardId', res.com_card_id);
            sessionStorage.setItem('loginMobile',this.publicData.loginMobile)//分享用
            this.publicData.com_binds.map((item,index)=>{
              this.companyStr += item.company_name + '、'
              this.companyStr1 += item.company_name + ','
            })
            sessionStorage.setItem("loginCard",res.loginCard)
            sessionStorage.setItem("customers",this.companyStr1)
					}
				})
      },
      methods:{
        mackMobile(val){
          if(!val){
            return ''
          }
          return val.replace(/(\d{3})\d{4}(\d{4})/,'$1****$2')
        },
        bindPerson(){
          getpublicbindstatus({
            openid: this.openId
          }).then((res)=>{
            if(res.wx_code == '0'){
              if(res.bind_status == '1'){
                Toast({
                  message: '切换成功',
                  icon: require('@/assets/img/bindSuccess.png'),
                  duration:'2000'
                });
                // setTimeout(()=>{
                  sessionStorage.removeItem('keyValue')
                  this.$router.push({ path: '/personalCenter'})
                // },1000)
              }else{
                //"binding_status:0--绑定成功（未绑定过,已绑定且数据一致,已绑定但数据需更新）;3--验证码不正确"
                doLogin({
                  openid: this.openId,
                  access_token: this.accessToken,
                  card_type:'身份证',
                  card_id: this.publicData.loginCard,
                  mobile: this.publicData.loginMobile
                }).then(res =>{
                  if(res.binding_status =='1'){
                    Toast({
                      message: '绑定成功！',
                      icon: require('@/assets/img/bindSuccess.png'),
                      duration:'3000'
                    });
                    sessionStorage.removeItem('keyValue')
                    this.$router.push({ path: '/personalCenter' });
                  }else{
                    Toast(res.msg)
                  }
                })
              }
            }
          })
        },
        handdleNext(Xindex,Xtext,Xcode){
          Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中"
          });
          this.getBindStatus(Xindex,Xtext,Xcode)
        },
        getBindStatus(Xindex,Xtext,Xcode){
          // console.log(this.bindStatus)
          const params = {
              data:{
                code:Xcode
              }
            }
            getWxMenuCue(params).then(res=>{
              Toast.clear();
              if(res.status == '200'){
                if(res.data.isOpen=='0'){
                  Dialog.alert({
                    message: res.data.cue
                  });
                }else{
                  this.nextTo(Xindex,Xtext,Xcode)
                }
              }else{
                Toast.fail({
                  message: res.msg,
                  duration:'3000'
                });
              }
            })
        },
        nextTo(Xindex,Xtext,Xcode){
          
          //公众号查询统计
          sessionStorage.setItem('menu_name',Xtext)
          const querytypeparams = {
            card_id: this.publicData.loginCard,
            function_type:'menu',
            menu_name:'公户_' + Xtext
          }
          querytype(querytypeparams);
          if(Xindex=='rentPlan'){
            //租金计划
            const params = {
              access_token: this.accessToken,
              card_id: this.publicData.loginCard
            }
            comconlist(params).then(res=>{
              Toast.clear();
              if(res.status == '200'){
                if(res.data.length > 0){
                  sessionStorage.removeItem('keyValue')
                  this.$router.push({ path: '/enterprise/contractList',query: {cardId: this.publicData.loginCard}});
                }else{
                  Toast('暂无有效合同')
                }
              }else{
                Toast.fail({
                  message: res.msg,
                  duration:'3000'
                });
              }
            })
          }else if(Xindex=='myApply'){
            sessionStorage.removeItem('keyValue');
            //我的申请
            Toast.clear();
            this.$router.push({ path: '/enterprise/enterpriseApplication?flag=1'});
          }else if(Xindex=='advanceDeposit'){
            sessionStorage.removeItem('keyValue')
            Toast.clear();
            //前置保证金
            this.$router.push({ path: '/enterprise/enterpriseAdvanceDeposit'});
          }
        },
        submit(){},
        isShowBind(){
          this.bindShow = true
        },
        closeBind(){
          this.bindShow = false
        }
      }
    }
  </script>
  <style scoped>
  .blockTop{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 20px 0px;
  }
  .lineLeft{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .headImg{
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
  .customerInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .customerText{
    color: #333333;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 8px;
  }
  .customerText1{
    color: #333333;
    font-size: 12px;
    line-height: 12px;
  }
  .lineRight{
    width: 106px;
    height: 27px;
    border: 1px solid #0256FF;
    border-radius: 27px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .changeImg{
    width: 16px;
    height: 16px;
  }
  .btnText{
    color: #0256FF;
    font-size: 14px;
  }
  .blockCenter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    background-color: #FFFFFF;
    padding: 0 20px;
    border-top: 1px solid #EEEEEE;
  }
  .center_text{
    width: 265px;
    line-height: 18px;
    color: #666666;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .viewBtn{
    width: 52px;
    height: 26px;
    background-color: #F0F0F0;
    border-radius: 26px;
    line-height: 26px;
    text-align: center;
    color: #333333;
  }
  .blockOne{
    background-color: #fff;
    margin-top: 10px;
  }
  .blockOne .header{
    margin: 10px;
    display: inline-block;
    font-size: 15px;
  }
  .wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  /* height: 20rem; */
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow-y: scroll;
  position: relative;
}
.closeTop{
  border-bottom: 1px solid #EEEEEE;
  
}
.closeImg{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.blockTitle{
  color: #1A1A1A;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  height: 56px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bindLine{
  margin-left: 20px;
  padding: 17px 20px 17px 0;
  border-bottom: 1px solid #EEEEEE;
}
.bindTop{
  color: #333333;
  font-size: 15px;
}
.bindBottom{
  color: #666666;
  font-size: 15px;
  margin-top: 10px;
}
  </style>